<template>
  <div>
    <b-card title="Makina Bilgileri">
      <machines />
    </b-card>
    <b-card title="Servis Özeti">
      <b-row>
        <b-col cols="12">
          <brands />
        </b-col>
        <b-col><complaints /></b-col>
        <b-col><transactions /></b-col>
      </b-row>
    </b-card>
    <b-card title="Yedek Parçalar">
      <spareparts v-if="dataItem.id_brands" />
      <b-alert
        v-else
        variant="warning"
        show
      >
        <div class="alert-body text-center">
          Yedek parça ürünü eklemek için önce marka seçimi yapın.
        </div>
      </b-alert>
    </b-card>
    <b-card title="Servis Detayı">
      <b-row>
        <b-col><customer-person /></b-col>
        <b-col><customer-technician /></b-col>
      </b-row>
      <b-row>
        <b-col><service-type /></b-col>
        <b-col><service-status /></b-col>
        <b-col cols="12">
          <service-crew />
        </b-col>
      </b-row>
      <works />
      <notes />
    </b-card>
    <save-button
      :save-action="submitForm"
      :is-disabled="submitStatus"
    />
  </div>
</template>
<script>
import {
  BRow, BCol, BCard, BAlert,
} from 'bootstrap-vue'
import Machines from '@/views/Admin/Services/elements/Machines.vue'
import Brands from '@/views/Admin/Services/elements/Brands.vue'
import Complaints from '@/views/Admin/Services/elements/Complaints.vue'
import Transactions from '@/views/Admin/Services/elements/Transactions.vue'
import Spareparts from '@/views/Admin/Services/elements/Spareparts.vue'
import Works from '@/views/Admin/Services/elements/Works.vue'
import Notes from '@/views/Admin/Services/elements/Notes.vue'
import CustomerPerson from '@/views/Admin/Services/elements/CustomerPerson.vue'
import CustomerTechnician from '@/views/Admin/Services/elements/CustomerTechnician.vue'
import ServiceType from '@/views/Admin/Services/elements/ServiceType.vue'
import ServiceStatus from '@/views/Admin/Services/elements/ServiceStatus.vue'
import ServiceCrew from '@/views/Admin/Services/elements/ServiceCrew.vue'
import SaveButton from '@/layouts/components/common/SaveButton.vue'

export default {
  name: 'ServiceForm',
  components: {
    BAlert,
    BRow,
    BCol,
    BCard,
    Machines,
    Brands,
    Complaints,
    Transactions,
    Spareparts,
    Works,
    Notes,
    CustomerPerson,
    CustomerTechnician,
    ServiceType,
    ServiceStatus,
    ServiceCrew,
    SaveButton,
  },
  props: {
    submitForm: {
      type: Function,
      required: true,
    },
    submitStatus: {
      type: Boolean,
      required: true,
    },
  },
  computed: {
    dataItem() {
      return this.$store.getters['services/dataItem']
    },
  },
}
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>
