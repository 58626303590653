<template>
  <b-row>
    <b-col>
      <b-form-group
        label="Başlangıç Tarih Saati"
        label-for="sdate"
      >
        <validation-provider
          #default="{ errors }"
          name="Başlangıç"
          rules="required"
        >
          <flat-pickr
            v-model="dataItem.sdate"
            class="form-control"
            placeholder="Seçiniz"
            :config="flatPickrConfig"
          />
          <small class="text-danger">{{ errors[0] }}</small>
        </validation-provider>
      </b-form-group>
    </b-col>
    <b-col>
      <b-form-group
        label="Bitiş Tarih Saati"
        label-for="edate"
      >
        <validation-provider
          #default="{ errors }"
          name="Bitiş"
          rules="required"
        >
          <flat-pickr
            v-model="dataItem.edate"
            class="form-control"
            placeholder="Seçiniz"
            :config="flatPickrConfig"
          />
          <small class="text-danger">{{ errors[0] }}</small>
        </validation-provider>
      </b-form-group>
    </b-col>
  </b-row>
</template>

<script>
import {
  BRow, BCol, BFormGroup,
} from 'bootstrap-vue'
import { ValidationProvider, localize } from 'vee-validate'
import { required } from '@validations'
import flatPickr from 'vue-flatpickr-component'
import { Turkish } from 'flatpickr/dist/l10n/tr.js'

export default {
  name: 'Works',
  components: {
    BRow,
    BCol,
    BFormGroup,
    flatPickr,
    ValidationProvider,
  },
  data() {
    return {
      required,
      flatPickrConfig: {
        enableTime: true,
        dateFormat: 'd.m.Y H:i',
        locale: Turkish,
      },
    }
  },
  computed: {
    dataItem() {
      return this.$store.getters['services/dataItem']
    },
  },
  created() {
    localize('tr')
  },
}
</script>
