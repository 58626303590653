<template>
  <div>
    <b-form-group
      label="Makina Kartı"
      label-for="title"
    >
      <validation-provider
        #default="{ errors }"
        name="Makina Kartı"
        rules="required"
      >
        <v-select
          v-model="dataItem.id_customer_machines"
          :options="dataList"
          label="title"
          :reduce="item => item.id"
          placeholder="Seçiniz"
          @input="getMachine"
        >
          <template v-slot:option="option">
            <div class="text-info">
              {{ option.title }}
            </div>
            <div class="font-small-2">
              {{ option.productName }}
            </div>
          </template>
        </v-select>
        <small class="text-danger">{{ errors[0] }}</small>
      </validation-provider>
    </b-form-group>
    <machine />
    <hr>
    <b-button
      v-b-toggle.machine-sidebar
      variant="primary"
      size="sm"
      @click="newMachine()"
    >
      <FeatherIcon icon="PlusCircleIcon" /> Makina Kartı Oluştur
    </b-button>
    <machine-sidebar />
  </div>
</template>

<script>
import { ValidationProvider, localize } from 'vee-validate'
import {
  BFormGroup, BButton, VBToggle,
} from 'bootstrap-vue'
import { required } from '@validations'
import vSelect from 'vue-select'
import Machine from '@/views/Admin/Services/View/Machine.vue'
import MachineSidebar from '@/views/Admin/Customers/MachineSidebar.vue'

export default {
  name: 'Machines',
  components: {
    BFormGroup,
    BButton,
    vSelect,
    ValidationProvider,
    Machine,
    MachineSidebar,
  },
  directives: {
    'b-toggle': VBToggle,
  },
  data() {
    return {
      required,
    }
  },
  computed: {
    dataItem() {
      return this.$store.getters['services/dataItem']
    },
    machine() {
      return this.$store.getters['customerMachines/dataItem']
    },
    dataList() {
      return this.$store.getters['customerMachines/dataList']
    },
    saveData() {
      return this.$store.getters['customerMachines/dataSaveStatus']
    },
  },
  watch: {
    saveData(val) {
      if (val.id) {
        this.getDataList()
      }
    },
  },
  created() {
    this.getDataList()
    localize('tr')
  },
  methods: {
    newMachine() {
      this.$store.commit('customerMachines/SET_DATA_ITEM', {
        id: null,
        title: null,
        serial: null,
        status: '1',
        notes: null,
        id_products: null,
        id_customers: this.$route.params.id_customers,
        id_customer_addresses: null,
      })
    },
    getMachine() {
      if (this.dataItem.id_customer_machines) {
        this.$store.dispatch('customerMachines/getDataItem', this.dataItem.id_customer_machines)
      } else {
        this.$store.commit('customerMachines/RESET_DATA_ITEM')
      }
    },
    getDataList() {
      this.$store.dispatch('customerMachines/getDataList', {
        select: [
          'customer_machines.id AS id',
          'customer_machines.title AS title',
          'products.title AS productName',
        ],
        where: {
          'customer_machines.id_customers': this.$route.params.id_customers,
        },
      })
    },
  },
}
</script>
