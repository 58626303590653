<template>
  <div>
    <b-row
      v-for="(serviceLines,key) in dataItem.service_lines"
      :key="key"
      class="d-flex align-items-center"
    >
      <b-col>
        <products :item-key="key" />
      </b-col>
      <b-col
        cols="12"
        md="2"
      >
        <quantity :item-key="key" />
      </b-col>
      <b-col
        cols="12"
        md="2"
      >
        <units :item-key="key" />
      </b-col>
      <b-col cols="auto">
        <b-button
          variant="outline-danger"
          @click="removeItem(key)"
        >
          <FeatherIcon icon="TrashIcon" />
        </b-button>
      </b-col>
    </b-row>
    <b-button
      variant="info"
      @click="addItem"
    >
      Parça Ekle
    </b-button>
  </div>
</template>

<script>
import {
  BRow, BCol, BButton,
} from 'bootstrap-vue'
import Products from '@/views/Admin/Services/elements/Spareparts/Products.vue'
import Quantity from '@/views/Admin/Services/elements/Spareparts/Quantity.vue'
import Units from '@/views/Admin/Services/elements/Spareparts/Units.vue'

export default {
  name: 'SpareParts',
  components: {
    BRow,
    BCol,
    BButton,
    Products,
    Quantity,
    Units,
  },
  data() {
    return {
      options: {
        float: {
          numeral: true,
          numeralDecimalMark: ',',
          delimiter: '',
        },
      },
    }
  },
  computed: {
    dataItem() {
      return this.$store.getters['services/dataItem']
    },
  },
  methods: {
    addItem() {
      this.dataItem.service_lines.push({
        id: null,
        quantity: null,
        id_units: null,
        id_products: null,
        id_product_groups: null,
        id_services: null,
      })
    },
    removeItem(index) {
      if (this.dataItem.service_lines[index].id) {
        this.$store.dispatch('services/removeLine', this.dataItem.service_lines[index].id)
      }
      this.dataItem.service_lines.splice(index, 1)
    },
  },
}
</script>
