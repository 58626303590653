<template>
  <b-row>
    <b-col>
      <b-form-group
        label="Yedek Parça Grubu"
        label-for="id_product_groups"
      >
        <validation-provider
          #default="{ errors }"
          name="Yedek Parça Grubu"
          rules="required"
        >
          <v-select
            id="id_product_groups"
            v-model="dataItem.service_lines[itemKey].id_product_groups"
            :options="productGroups"
            label="title"
            :reduce="item => item.id"
            placeholder="Seçiniz"
            @input="changeGroup"
          />
          <small class="text-danger">{{ errors[0] }}</small>
        </validation-provider>
      </b-form-group>
    </b-col>
    <b-col>
      <b-form-group
        label="Yedek Parça"
        label-for="title"
      >
        <validation-provider
          #default="{ errors }"
          name="Yedek Parça"
          rules="required"
        >
          <v-select
            v-model="dataItem.service_lines[itemKey].id_products"
            :options="products"
            label="title"
            :reduce="item => item.id"
            placeholder="Seçiniz"
            :disabled="!dataItem.service_lines[itemKey].id_product_groups"
          />
          <small class="text-danger">{{ errors[0] }}</small>
        </validation-provider>
      </b-form-group>
    </b-col>
  </b-row>
</template>

<script>
import { ValidationProvider, localize } from 'vee-validate'
import {
  BRow, BCol, BFormGroup,
} from 'bootstrap-vue'
import { required } from '@validations'
import vSelect from 'vue-select'

export default {
  name: 'Products',
  components: {
    BRow,
    BCol,
    BFormGroup,
    vSelect,
    ValidationProvider,
  },
  props: {
    itemKey: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      products: [],
      required,
    }
  },
  computed: {
    dataItem() {
      return this.$store.getters['services/dataItem']
    },
    productGroups() {
      return this.$store.getters['productGroups/dataList']
    },
  },
  created() {
    this.getDataList()
    localize('tr')
    this.getProducts(this.dataItem.service_lines[this.itemKey].id_product_groups)
  },
  methods: {
    getDataList() {
      this.$store.dispatch('productGroups/getDataList', {
        select: [
          'product_groups.id AS id',
          'product_groups.title AS title',
        ],
        where: {
          'product_groups.id_product_groups': 33,
          'product_groups.id_brands': this.dataItem.id_brands,
        },
      })
    },
    changeGroup() {
      this.dataItem.service_lines[this.itemKey].id_products = null
      this.getProducts(this.dataItem.service_lines[this.itemKey].id_product_groups)
    },
    getProducts(id) {
      if (id) {
        this.$store.dispatch('products/getDataList', {
          select: [
            'products.id AS id',
            'products.title AS title',
          ],
          where: {
            'products.id_product_groups': id,
          },
        })
          .then(res => {
            this.products = res
          })
      }
    },
  },
}
</script>
